import PropTypes from 'prop-types';
import { VerticalLayout } from 'src/components/navbar/vertical-layout';
import { withAuthGuard } from 'src/guards/with-auth-guard';
import { useSettings } from 'src/hooks/use-settings';
import { useSections } from 'src/components/navbar/config';

export const SidebarLayout = withAuthGuard((props) => {
  const settings = useSettings();
  const sections = useSections();

  return (
    <VerticalLayout
      sections={sections}
      navColor={settings.navColor}
      {...props}
    />
  );
});

export default SidebarLayout;
