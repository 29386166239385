import { Suspense, lazy } from 'react';
import SuspenseLoader from '../components/SuspenseLoader';
import { Outlet } from 'react-router-dom';
import { componentsRoutes } from './components';

/* eslint-disable react/display-name */
const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Error401Page = Loader(lazy(() => import('src/pages/401')));
const Error404Page = Loader(lazy(() => import('src/pages/404')));
const Error500Page = Loader(lazy(() => import('src/pages/500')));
/* eslint-enable react/display-name */

export const routes = [
  {
    path: '/',
    element: (
      <Suspense>
        <Outlet />
      </Suspense>
    ),
    children: [...componentsRoutes],
  },
  {
    path: '401',
    element: <Error401Page />,
  },
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: '500',
    element: <Error500Page />,
  },
  {
    path: '*',
    element: <Error404Page />,
  },
];
