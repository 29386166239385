import { useMemo } from 'react';
import ShoppingBag03Icon from 'src/icons/untitled-ui/duocolor/shopping-bag-03';
import Truck01Icon from 'src/icons/untitled-ui/duocolor/truck-01';
import OrderIcon from 'src/icons/untitled-ui/duocolor/shopping-cart-01';
import { paths } from 'src/paths';
import { useAuth } from 'src/hooks/use-auth';

export const useSections = () => {
  const auth = useAuth();
  return useMemo(() => {
    const items = [
      {
        title: 'Ship orders',
        path: paths.shipOrders,
        icon: <Truck01Icon />,
      },
      {
        title: 'Box types',
        path: paths.box,
        icon: <ShoppingBag03Icon />,
      },
      {
        title: 'Orders',
        path: paths.orders,
        icon: <OrderIcon />,
      },
    ];

    return [
      {
        items: auth.isAuthenticated ? items.filter(x => rolePages[auth.user.role].includes(x.path)) : []
      },
    ];
  }, [auth]);
};

export const rolePages = {
    'Admin': ['/Orders'],
    'Secretary': ['/ShipOrders', '/BoxTypes', '/Orders'],
    'Employee': ['/ShipOrders', '/BoxTypes', '/Orders'],
    'SuperAdmin': ['/Orders'],
    'WareHouseUser': ['/Orders'],
    'USER': ['/Orders']
  }

export const adminsRoles = ['Secretary', 'Employee']
