export const paths = {
  index: '/',
  box: '/BoxTypes',
  shipOrders: '/ShipOrders',
  orders: '/Orders',
  auth: {
    jwt: {
      login: '/Login',
    },
    notAuthorized: '/401',
    notFound: '/404',
    serverError: '/500',
  },
};
