import { decode } from 'src/utils/jwt';
import axios from 'axios';
import { storage, api } from 'src/config';

const axiosInstance = axios.create({ baseURL: api.baseUrl + '/auth' });
axiosInstance.interceptors.request.use(
  (config) => {
    const token = authApi.token();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

class AuthApi {
  STORAGE_KEY = storage.jwt;

  async signIn(dto) {
    try {
      const response = await axiosInstance.post('/login', dto);
      return response.data.accessToken;
    } catch (error) {
      return this.handleError(error);
    }
  }

  async signData(dto) {
    try {
      const response = await axiosInstance.post('/sign', dto);
      return response.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  me(token) {
    return { email: decode(token).email, role: decode(token)['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] };
  }

  token() {
    return sessionStorage.getItem(this.STORAGE_KEY);
  }

  handleError(error) {
    if (error.response) {
      // console.error('Error Response', error.response.data);
      return { error: error.response };
    } else if (error.request) {
      // console.error('Error Request', error.request);
      return { error: 'No response from server' };
    } else if (error.message) {
      // console.error('Error', error.message);
      return { error: error.message };
    } else {
      // console.error('Unknown Error', error);
      return { error: 'Unknown error occurred' };
    }
  }
}
export const authApi = new AuthApi();
