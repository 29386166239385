import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box } from '@mui/material';
import { CircularProgress } from './circular-progress';

function SuspenseLoader() {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress
        size={64}
        disableShrink
        thickness={3}
        value={0}
      />
    </Box>
  );
}

export default SuspenseLoader;
