import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { SidebarLayout } from 'src/pages/SidebarLayout';
import { decode } from 'src/utils/jwt';
import { storage } from 'src/config';

const BoxTypePage = lazy(() => import('src/pages/BoxTypePage'));
const OrdersPage = lazy(() => import('src/pages/OrdersPage'));
const ShipOrdersPage = lazy(() => import('src/pages/ShipOrdersPage'));
const LoginPage = lazy(() => import('src/pages/LoginPage'));
const PrivateRoute = lazy(() => import('src/routes/PrivateRoute'));

export const componentsRoutes = [
  {
    path: '',
    element: (
      <SidebarLayout>
        <Outlet />
      </SidebarLayout>
    ),
    children: [
      {
        path: '',
        element: <Navigate to={'/ShipOrders'} />,
        index: true,
      },
      {
        path: 'BoxTypes',
        element: <PrivateRoute 
                    element={<BoxTypePage />} 
                    allowedGroups={['Secretary', 'Employee']}/>,
      },
      {
        path: 'ShipOrders',
        element: <PrivateRoute 
                    element={<ShipOrdersPage />} 
                    allowedGroups={['Secretary', 'Employee']}/>,
      },
      {
        path: 'Orders',
        element: <PrivateRoute
                    element={<OrdersPage />} 
                    allowedGroups={['Admin', 'Secretary', 'Employee', 'SuperAdmin', 'WareHouseUser', 'USER']}/>,
      },
    ],
  },
  {
    path: 'LogIn',
    element: <LoginPage />,
  },
  {
    path: 'Login',
    element: <LoginPage />,
  },
];
