import { Box } from '@mui/system';
import { useSettings } from 'src/hooks/use-settings';

export const Logo = () => {
  const isDark = useSettings().paletteMode === 'dark';
  return (
    <Box>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        height="43"
        viewBox="0 0 118 43"
        width="118"
      >
        <g
          clipPath="url(#clip0)"
          fill="none"
        >
          <path
            clipRule="evenodd"
            d="M29.7242 27.1432C29.7242 29.4257 29.3348 31.5087 28.5561 33.3923C27.7772 35.2762 26.7175 36.8929 25.3772 38.2425C24.0368 39.5919 22.4654 40.6378 20.6631 41.3804C18.8608 42.1231 16.9272 42.4945 14.8623 42.4945C12.8153 42.4945 10.8907 42.1141 9.08837 41.3534C7.28607 40.5927 5.71023 39.533 4.36086 38.1744C3.01127 36.8159 1.94712 35.1992 1.16841 33.3246C0.389477 31.4497 0 29.3893 0 27.1432V1.2771H7.44478V14.8621C7.84312 14.355 8.32762 13.9022 8.89828 13.5037C9.46872 13.1053 10.0846 12.7793 10.7458 12.5257C11.407 12.272 12.0907 12.0773 12.7971 11.9416C13.5037 11.8057 14.1921 11.7377 14.8623 11.7377C16.9272 11.7377 18.8608 12.1226 20.6631 12.8923C22.4654 13.6623 24.0368 14.7355 25.3772 16.1121C26.7175 17.4886 27.7772 19.1143 28.5561 20.9892C29.3348 22.8639 29.7242 24.9152 29.7242 27.1432ZM22.2525 27.1432C22.2525 26.0201 22.0578 24.9741 21.6684 24.0051C21.2788 23.0361 20.749 22.1983 20.0788 21.4917C19.4086 20.7853 18.6252 20.2329 17.7285 19.8343C16.8318 19.4359 15.8764 19.2368 14.8623 19.2368C13.8479 19.2368 12.8924 19.4586 11.9957 19.9024C11.099 20.3461 10.3156 20.9348 9.64543 21.6686C8.97524 22.4021 8.44995 23.2443 8.06958 24.1951C7.68922 25.1462 7.49904 26.1289 7.49904 27.1432C7.49904 28.2664 7.68922 29.3079 8.06958 30.268C8.44995 31.228 8.97524 32.0612 9.64543 32.7675C10.3156 33.4739 11.099 34.0309 11.9957 34.4384C12.8924 34.846 13.8479 35.0497 14.8623 35.0497C15.8764 35.0497 16.8318 34.846 17.7285 34.4384C18.6252 34.0309 19.4086 33.4739 20.0788 32.7675C20.749 32.0612 21.2788 31.228 21.6684 30.268C22.0578 29.3079 22.2525 28.2664 22.2525 27.1432Z"
            fill={isDark ? '#ffffff' : '#042743'}
            fillRule="evenodd"
          />
          <path
            d="M40.5224 1.2771V41.9512H33.0507V1.2771H40.5224Z"
            fill={isDark ? '#ffffff' : '#042743'}
          />
          <path
            d="M53.6458 1.2771V41.9512H46.174V1.2771H53.6458Z"
            fill={isDark ? '#ffffff' : '#042743'}
          />
          <path
            d="M68.0188 4.94488C68.0188 5.63327 67.8875 6.27627 67.6249 6.8739C67.3622 7.47174 67.0046 7.99253 66.5519 8.43625C66.0989 8.87998 65.569 9.23316 64.9622 9.4958C64.3554 9.75844 63.7079 9.88976 63.0197 9.88976C62.3313 9.88976 61.6837 9.75844 61.0769 9.4958C60.47 9.23316 59.9448 8.87998 59.501 8.43625C59.0573 7.99253 58.7041 7.47174 58.4415 6.8739C58.1788 6.27627 58.0475 5.63327 58.0475 4.94488C58.0475 4.27469 58.1788 3.63618 58.4415 3.02934C58.7041 2.42251 59.0573 1.89724 59.501 1.45351C59.9448 1.00978 60.47 0.656596 61.0769 0.393956C61.6837 0.131317 62.3313 0 63.0197 0C63.7079 0 64.3554 0.131317 64.9622 0.393956C65.569 0.656596 66.0989 1.00978 66.5519 1.45351C67.0046 1.89724 67.3622 2.42251 67.6249 3.02934C67.8875 3.63618 68.0188 4.27469 68.0188 4.94488Z"
            fill={isDark ? '#ffffff' : '#042743'}
          />
          <path
            d="M66.7694 12.8516V41.9513H59.2973V12.8516H66.7694Z"
            fill={isDark ? '#ffffff' : '#042743'}
          />
          <path
            d="M88.5597 27.0347L99.2104 41.9512H90.1355L84.3484 33.0391L78.5068 41.9512H69.4319L80.0553 27.0347L69.4319 12.6887H78.5068L84.3484 21.0299L90.1355 12.6887H99.2104L88.5597 27.0347Z"
            fill={isDark ? '#ffffff' : '#042743'}
          />
          <path
            clipRule="evenodd"
            d="M110.87 18.4357C114.919 17.721 118 14.1812 118 9.92725C118 5.15864 114.129 1.28711 109.36 1.28711C104.591 1.28711 100.72 5.15864 100.72 9.92725C100.72 14.178 103.796 17.7162 107.842 18.4345V10.4756L105.133 13.0224V9.23019L109.36 5.06038L113.587 9.23019V13.0224L110.87 10.4756V18.4357Z"
            fill="#F1A900"
            fillRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              fill="white"
              height="42.4943"
              width="118"
            />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};
