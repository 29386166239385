import { jwtDecode } from 'jwt-decode';

/* eslint-disable no-bitwise */
export const JWT_SECRET = 'change-this-if-needed';
export const JWT_EXPIRES_IN = 3600 * 24 * 2; // 2 days

export const decode = (token) => {
  return jwtDecode(token);
};

export const verify = (token, privateKey) => {
  const [encodedHeader, encodedPayload, signature] = token.split('.');
  const header = JSON.parse(atob(encodedHeader));
  const payload = JSON.parse(atob(encodedPayload));
  const now = new Date();

  if (now < header.expiresIn) {
    throw new Error('Expired token');
  }

  if (privateKey) {
    const verifiedSignature = btoa(
      Array.from(encodedPayload)
        .map((item, key) =>
          String.fromCharCode(
            item.charCodeAt(0) ^ privateKey[key % privateKey.length].charCodeAt(0)
          )
        )
        .join('')
    );

    if (verifiedSignature !== signature) {
      throw new Error('Invalid signature');
    }
  }

  return payload;
};
