import { useCallback } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Box, Button, Divider, Popover, Typography } from '@mui/material';
import { useAuth } from 'src/hooks/use-auth';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';
import { OptionsColorScheme } from '../options-color-scheme';
import { useSettings } from 'src/hooks/use-settings';

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const router = useRouter();
  const { user, signOut } = useAuth();
  const settings = useSettings();

  const handleFieldUpdate = useCallback(
    (field, value) => {
      settings.handleUpdate?.({
        [field]: value,
      });
    },
    [settings]
  );

  const handleLogout = useCallback(async () => {
    try {
      onClose?.();
      await signOut();
      router.push(paths.auth.jwt.login);
    } catch (err) {
      toast.error('Something went wrong!');
    }
  }, [router, onClose, signOut]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}
    >
      {user && (
        <Box sx={{ p: 2 }}>
          <Typography variant="body2">{user?.email}</Typography>
        </Box>
      )}
      <Divider />
      <Box sx={{ p: 1 }}>
        <OptionsColorScheme
          onChange={(value) => handleFieldUpdate('paletteMode', value)}
          value={settings.paletteMode}
        />
      </Box>
      <Divider sx={{ my: '0 !important' }} />
      <Box
        sx={{
          display: 'flex',
          p: 1,
          justifyContent: 'center',
        }}
      >
        <Button
          color="inherit"
          onClick={handleLogout}
          size="small"
        >
          Logout
        </Button>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
