import React from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useRoutes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import 'src/global.css';
import { routes } from 'src/routes';
import { SplashScreen } from 'src/components/SplashScreen/splash-screen';
import { AuthConsumer, AuthProvider } from 'src/contexts/auth/jwt';
import { SettingsConsumer, SettingsProvider } from 'src/contexts/settings';
import { createTheme } from 'src/theme';
import { Toaster } from 'src/components/Toast/toaster';

import 'simplebar-react/dist/simplebar.min.css';

export default function App() {
  const content = useRoutes(routes);

  return (
    <HelmetProvider>
      <AuthProvider>
        <AuthConsumer>
          {(auth) => (
            <SettingsProvider>
              <SettingsConsumer>
                {(settings) => {
                  if (!settings.isInitialized) {
                    return null;
                  }

                  const theme = createTheme({
                    colorPreset: settings.colorPreset,
                    contrast: settings.contrast,
                    direction: settings.direction,
                    paletteMode: settings.paletteMode,
                    responsiveFontSizes: settings.responsiveFontSizes,
                  });

                  // Prevent guards from redirecting
                  const showSlashScreen = !auth.isInitialized;

                  return (
                    <ThemeProvider theme={theme}>
                      <Helmet>
                        <meta
                          name="color-scheme"
                          content={settings.paletteMode}
                        />
                        <meta
                          name="theme-color"
                          content={theme.palette.neutral[900]}
                        />
                      </Helmet>
                      <CssBaseline />
                      {showSlashScreen ? <SplashScreen /> : <>{content}</>}
                      <Toaster />
                    </ThemeProvider>
                  );
                }}
              </SettingsConsumer>
            </SettingsProvider>
          )}
        </AuthConsumer>
      </AuthProvider>
    </HelmetProvider>
  );
}
